.center {
  display: flex;
  justify-content: center;
}

.icon {
  color: var(--vkui--color_icon_accent);
}

.title {
  color: var(--vkui--color_text_accent);
}

.after {
  display: flex;
}
