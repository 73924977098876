.FormLayoutGroup--mode-horizontal {
  display: flex;
  padding: var(--vkui--size_form_item_padding_vertical--regular)
    var(--vkui--size_base_padding_horizontal--regular);
  flex-wrap: wrap;
  align-items: flex-start;
}

.FormLayoutGroup__removable {
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.FormLayoutGroup__offset {
  display: none;
}

.FormLayoutGroup--mode-horizontal
  :global(.vkuiInternalFormItem--withTop)
  ~ .FormLayoutGroup__offset {
  order: -1;
  display: block;
  min-width: 100%;
  height: calc(2px + var(--vkui--font_subhead--line_height--regular) + 8px);
}

.FormLayoutGroup--sizeY-compact.FormLayoutGroup--mode-horizontal
  :global(.vkuiInternalFormItem--withTop)
  ~ .FormLayoutGroup__offset {
  height: calc(2px + var(--vkui--font_subhead--line_height--compact) + 6px);
}

@media (--sizeY-compact) {
  .FormLayoutGroup--sizeY-none.FormLayoutGroup--mode-horizontal
    :global(.vkuiInternalFormItem--withTop)
    ~ .FormLayoutGroup__offset {
    height: calc(2px + var(--vkui--font_subhead--line_height--compact) + 6px);
  }
}

.FormLayoutGroup--removable {
  padding-left: 0;
  padding-right: 0;
}

.FormLayoutGroup--segmented {
  position: relative;
}

/* stylelint-disable vkui/atomic */
.FormLayoutGroup--segmented > * {
  min-width: 0;
  flex: 1;
}

.FormLayoutGroup--segmented > *:not(:first-child):not(.FormLayoutGroup__offset) {
  margin-left: calc(-1 * var(--vkui_internal--thin_border));
}
