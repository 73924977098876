.Badge {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.Badge--mode-new {
  background-color: var(--vkui--color_icon_accent);
}

.Badge--mode-prominent {
  background-color: var(--vkui--color_accent_red);
}

/**
 * CMP:
 * TabbarItem
 */
:global(.vkuiInternalTabbarItem__label) .Badge {
  position: absolute;
  top: -2px;
  right: -4px;
}
