.wrap * {
  word-break: break-word;
  white-space: break-spaces;
  user-select: auto;
}

.wrap {
  width: 100%;
}

.wrap + .wrap:before {
  content: ' ';
  display: block;
  width: calc(100% - 32px);
  height: 0;
  margin: 0 auto;
  border-top: 1px solid var(--vkui--color_separator_primary);
}

.selected {
  background-color: var(--vkui--color_background--hover);
}

.checkbox.checkbox {
  padding-left: 0;
}

.checkbox.checkbox label {
  margin-right: 10px;
}

.removeBtn {
  color: var(--vkui--color_icon_negative);
  cursor: pointer;
}

.dateCheckbox.dateCheckbox {
  padding-left: 0;
}

.icon {
  color: var(--vkui--color_icon_accent);
}
