.VisuallyHidden {
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  margin: -1px !important; /* Fix for https://github.com/twbs/bootstrap/issues/25686 */
  white-space: nowrap !important;
  clip: rect(0, 0, 0, 0) !important;
  clip-path: inset(50%);
  overflow: hidden !important;
  border: 0 !important;
  opacity: 0;
}
