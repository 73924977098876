.PanelHeaderContext {
  z-index: var(--vkui_internal--z_index_panel_header_context);
}

.PanelHeaderContext--opened,
.PanelHeaderContext--closing {
  width: 100%;
  height: 100%;
}

.PanelHeaderContext--sizeX-regular.PanelHeaderContext--opened,
.PanelHeaderContext--sizeX-regular.PanelHeaderContext--closing {
  height: auto;
}

@media (--sizeX-regular) {
  .PanelHeaderContext--sizeX-none.PanelHeaderContext--opened,
  .PanelHeaderContext--sizeX-none.PanelHeaderContext--closing {
    height: auto;
  }
}

.PanelHeaderContext__in {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  pointer-events: none;
}

.PanelHeaderContext__fade {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}

.PanelHeaderContext--sizeX-compact .PanelHeaderContext__fade {
  display: block;
}

@media (--sizeX-compact) {
  .PanelHeaderContext--sizeX-none .PanelHeaderContext__fade {
    display: block;
  }
}

.PanelHeaderContext--opened .PanelHeaderContext__fade {
  animation: vkui-animation-panelheadercontext-fade-in 0.2s ease;
}

.PanelHeaderContext--closing .PanelHeaderContext__fade {
  animation: vkui-animation-panelheadercontext-fade-out 0.2s ease;
}

.PanelHeaderContext--opened .PanelHeaderContext__in {
  pointer-events: auto;
  animation: vkui-animation-panelheadercontext-translate-in 0.2s ease;
}

.PanelHeaderContext--closing .PanelHeaderContext__in {
  animation: vkui-animation-panelheadercontext-translate-out 0.2s ease;
}

.PanelHeaderContext__content {
  overflow: hidden;
  background: var(--vkui--color_background_content);
}

.PanelHeaderContext--ios .PanelHeaderContext__in {
  padding: 0;
}

/**
 * Имеется ввиду
 * platform !== Platform.IOS ||
 * (platform === Platform.IOS && isDesktop)
 */
.PanelHeaderContext:not(.PanelHeaderContext--ios) .PanelHeaderContext__content,
.PanelHeaderContext--sizeX-regular.PanelHeaderContext--ios .PanelHeaderContext__content {
  border-radius: 12px;
  padding: 8px 0;
}

@media (--sizeX-regular) {
  .PanelHeaderContext--sizeX-none.PanelHeaderContext--ios .PanelHeaderContext__content {
    border-radius: 12px;
    padding: 8px 0;
  }
}

/*
 Desktop
 */

.PanelHeaderContext--sizeX-regular .PanelHeaderContext__in {
  padding: 12px 8px;
}

.PanelHeaderContext--sizeX-regular .PanelHeaderContext__content {
  border-radius: 12px;
}

.PanelHeaderContext--sizeX-regular .PanelHeaderContext__content {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 8px 8px rgba(0, 0, 0, 0.16);
}

@media (--sizeX-regular) {
  .PanelHeaderContext--sizeX-none .PanelHeaderContext__in {
    padding: 12px 8px;
  }

  .PanelHeaderContext--sizeX-none .PanelHeaderContext__content {
    border-radius: 12px;
  }

  .PanelHeaderContext--sizeX-none .PanelHeaderContext__content {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 8px 8px rgba(0, 0, 0, 0.16);
  }
}

/*
  Animations
 */

@keyframes vkui-animation-panelheadercontext-translate-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes vkui-animation-panelheadercontext-translate-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes vkui-animation-panelheadercontext-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes vkui-animation-panelheadercontext-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
